import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskDirective } from 'ngx-mask';
import { DurationInputControlModule } from '../duration-input-control/duration-input-control.module';
import { UserGroupSelectControlModule } from '../user-group-select-control/user-group-select-control.module';
import { CreateTaskDialogComponent } from './create-task-dialog.component';

@NgModule({
  declarations: [CreateTaskDialogComponent],
  imports: [
    CommonModule,
    MaterialModule,

    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgxMaskDirective,
    UserGroupSelectControlModule,
    DurationInputControlModule,
  ],
  exports: [CreateTaskDialogComponent],
})
export class CreateTaskDialogModule {}
